import { Col, Row, Segmented } from 'antd';
import { CardListLayout } from '../layouts/PageLayout';
import { SegmentedValue } from 'antd/es/segmented';
import { MesDeProcessamentoDatePicker } from '../pages';

type DataPickerType = {
	layoutOptions: {
		label: JSX.Element;
		value: string;
	}[];
	handleChangeLayout: (e: SegmentedValue) => void;
	layout: CardListLayout;
};

const DataPicker: React.FC<DataPickerType> = ({ layoutOptions, layout, handleChangeLayout }) => {
	return (
		<>
			<Row className='justify-content-between'>
				<Col>
					<MesDeProcessamentoDatePicker />
				</Col>
				<Col>
					<Segmented options={layoutOptions} value={layout} onChange={handleChangeLayout} />
				</Col>
			</Row>
		</>
	);
};

export default DataPicker;
