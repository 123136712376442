import * as Sentry from '@sentry/react';
import React from 'react';

import {
	createBrowserRouter,
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router-dom';

export const sentrySetup = () => {
	Sentry.init({
		dsn: 'https://b44517177fc14f46b52f81e3a26a1906@sentry.monitoramento.jcmsystem.com.br/4',
		integrations: [
			Sentry.replayIntegration({
				// Additional SDK configuration goes in here, for example:
				maskAllText: true,
				blockAllMedia: true,
			}),
			Sentry.reactRouterV6BrowserTracingIntegration({
				useEffect: React.useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			}),

			// Tipagem da biblioteca está mal definida, mas funciona
			// @ts-ignore
			Sentry.httpClientIntegration(),
			// @ts-ignore
			Sentry.captureConsoleIntegration(),
		],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,

		// Capture Replay for 10% of all sessions,
		// plus for 100% of sessions with an error
		replaysSessionSampleRate: 0.25,
		replaysOnErrorSampleRate: 1.0,
	});
};

export const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
