import { memo, useContext, useEffect } from 'react';
import { DataContext } from '../../store';
import PageLayout from '../../layouts/PageLayout';
import { TipoModulo } from '../../helpers/enums';

const Tesouraria = memo(() => {
	const { changeTipoModulo, arquivos, isError } = useContext(DataContext);

	useEffect(() => {
		changeTipoModulo(TipoModulo.Atuarial);
	}, [changeTipoModulo]);

	return <PageLayout arquivos={arquivos} isError={isError} />;
});

export default Tesouraria;
