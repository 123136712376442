import { Result } from 'antd';
import { memo } from 'react';

const ErroCardList = memo(() => {
	return (
		<div id='erro-card-list'>
			<Result
				status='error'
				title='Houve um problema ao exibir os arquivos...'
				subTitle='Por favor, tente recarregar a página. Caso o problema persista, entre em contato com o suporte técnico.'
			/>
		</div>
	);
});

export default ErroCardList;
