import { AppstoreOutlined, FilterFilled, UnorderedListOutlined } from '@ant-design/icons';
import { GhostButton } from '@jcm/poc-design-system';
import { Col, Popover, Row, Segmented, Space } from 'antd';
import { SegmentedValue } from 'antd/es/segmented';
import { memo, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';

import { ArquivoStatusIDType, ArquivoType, LocalStorageContext } from '../../store';

import ArquivosCardList from '../../components/ArquivosCardList';
import ErroCardList from '../../components/ErroCardList';
import FilterPopover from '../../components/FilterPopover';

import { CardListLayout, PageLayoutReducerType } from './index.types';

import colors from '../../assets/scss/_colors.module.scss';
import styles from './index.module.scss';
import DataPicker from '../../components/DataPicker';

export const pageLayoutReducer = (prev: PageLayoutReducerType, next: Partial<PageLayoutReducerType>) => {
	return { ...prev, ...next };
};

type PageLayoutProps = {
	arquivos: ArquivoType[];
	isError: boolean;
};

const PageLayout = memo(({ arquivos, isError }: PageLayoutProps) => {
	const { getItem, setItem } = useContext(LocalStorageContext);

	const initialState = useMemo<PageLayoutReducerType>(() => {
		return {
			tipoDeArquivo: 'total',
			searchQuery: '',
			statusFilter: [],
			isFilterActive: false,
			isFilterPopoverOpen: false,
			isActiveFilterPopoverOpen: false,
			layout: (getItem('lastHomeCardListLayout') ?? 'cards') as CardListLayout,
		};
	}, [getItem]);

	const [state, dispatch] = useReducer(pageLayoutReducer, initialState);

	// Armazena layout
	useEffect(() => {
		return setItem('lastHomeCardListLayout', state.layout);
	}, [state.layout, setItem]);

	useEffect(() => {
		dispatch({ isFilterActive: !!state.searchQuery || state.statusFilter.length > 0 });
	}, [state.searchQuery, state.statusFilter]);

	const handleChangeLayout = useCallback((e: SegmentedValue) => {
		dispatch({ layout: e as CardListLayout });
	}, []);

	const arquivosFiltrados = useMemo(() => {
		const filtrados = arquivos.filter(({ programado_SN, status, nomePatrocinadora }) => {
			// Filtro mais 'estreito', o primeiro
			const getFilterSearchQuery = () => {
				return state.searchQuery === '' || nomePatrocinadora.toLowerCase().includes(state.searchQuery.toLowerCase());
			};

			const getFilterStatus = () => {
				return state.statusFilter.length === 0 || state.statusFilter.includes(status.situacaoImportacaoID);
			};

			// Filtro mais geral, o último
			const getFilterTipo = () => {
				return state.tipoDeArquivo === 'total' || state.tipoDeArquivo === String(programado_SN);
			};

			return getFilterSearchQuery() && getFilterStatus() && getFilterTipo();
		});

		return filtrados;
	}, [arquivos, state.searchQuery, state.statusFilter, state.tipoDeArquivo]);

	const layoutOptions = useMemo(() => {
		return [
			{ label: <AppstoreOutlined />, value: 'cards' },
			{ label: <UnorderedListOutlined />, value: 'list' },
		];
	}, []);

	const handleChangeSearchQuery = useCallback((e: any) => {
		dispatch({ searchQuery: e.target.value });
	}, []);

	const handleChangeFilterPopover = useCallback(
		(open: boolean) => {
			dispatch({ isFilterPopoverOpen: open, isActiveFilterPopoverOpen: !open && state.isFilterActive });
		},
		[state.isFilterActive]
	);

	const handleChangeStatusFilter = useCallback((values: number[]) => {
		dispatch({ statusFilter: values as ArquivoStatusIDType[] });
	}, []);

	return (
		<section className={styles.content} id='home-page'>
			<Space direction='vertical' className='w-100'>
				<DataPicker layoutOptions={layoutOptions} layout={state.layout} handleChangeLayout={handleChangeLayout} />
				<Row className='justify-content-between'>
					<Col>
						<Space size='large'>
							<Segmented
								options={[
									{ label: 'Arquivos programados', value: String(true) },
									{ label: 'Arquivos eventuais', value: String(false) },
									{ label: 'Total', value: 'total' },
								]}
								value={state.tipoDeArquivo}
								onChange={(e) => dispatch({ tipoDeArquivo: e })}
							/>
							<Popover
								placement='right'
								trigger='click'
								title='Filtros'
								open={state.isFilterPopoverOpen}
								onOpenChange={handleChangeFilterPopover}
								content={
									<FilterPopover
										searchQuery={state.searchQuery}
										onChangeSearchQuery={handleChangeSearchQuery}
										statusFilter={state.statusFilter}
										onChangeStatusFilter={handleChangeStatusFilter}
									/>
								}
							>
								<Popover
									content={<div className={styles.activeFilterPopover}>Há filtros ativos!</div>}
									placement='right'
									open={state.isActiveFilterPopoverOpen}
									color={colors.primary}
								>
									<GhostButton icon={<FilterFilled />} size='middle' type={state.isFilterActive ? 'primary' : 'text'} />
								</Popover>
							</Popover>
						</Space>
					</Col>
				</Row>
				{isError ? <ErroCardList /> : <ArquivosCardList layout={state.layout} items={arquivosFiltrados} />}
			</Space>
		</section>
	);
});

export default PageLayout;
export * from './index.types';
