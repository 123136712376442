import { Suspense, lazy, memo, useCallback, useMemo, useState } from 'react';

import { ApiUsuarioType, getFormatoFromFormatoArquivoID, getStatusFromArquivoStatusID } from '../../../store';

import ArquivoCardItemFallback from './ArquivoCardItemFallback';
import ArquivoListItemFallback from './ArquivoListItemFallback';

import { IAdicionarArquivoModalProps } from '../../Modals/AdicionarArquivoModal';

import { IArquivoCardListItemProps, IItemProps } from './index.types';

const ArquivoCardItem = lazy(() => import('./ArquivoCardItem'));
const ArquivoListItem = lazy(() => import('./ArquivoListItem'));

const ArquivoCardListItem: React.FC<IArquivoCardListItemProps> = memo(({ item, layout, isLoading, setModalProps }) => {
	const [isModalLoading, setIsModalLoading] = useState(false);

	const envioMaisRecente = useMemo(() => item.envio[0], [item.envio]);

	const onCancel = useCallback(() => setModalProps?.({ open: false }), [setModalProps]);

	const onLoading = useCallback((loading: boolean) => setIsModalLoading(loading), []);

	const statusBadgeProps = useMemo(
		() => ({
			...getStatusFromArquivoStatusID(item.status.situacaoImportacaoID),
			label: item.status.situacaoImportacao,
		}),
		[item.status.situacaoImportacaoID, item.status.situacaoImportacao]
	);

	const responsavel = useMemo<ApiUsuarioType | undefined>(() => {
		if (!envioMaisRecente) return;

		const fallback: ApiUsuarioType = { usuarioSistemaID: '1', nomeUsuario: 'Usuário' };
		return envioMaisRecente.dadosUsuario?.usuarioSistemaID ? envioMaisRecente.dadosUsuario : fallback;
	}, [envioMaisRecente]);

	const formatoArquivo = useMemo(() => {
		return getFormatoFromFormatoArquivoID(item.tipoFormatoArquivoID);
	}, [item.tipoFormatoArquivoID]);

	const openModal = useCallback(() => {
		const props: IAdicionarArquivoModalProps = {
			open: true,
			tipoArquivoImportacaoID: item.tipoArquivoImportacaoID,
			tipoArquivoMovimentoID: item.tipoArquivoMovimentoID,
			formatoArquivo: formatoArquivo.mimetype,
			onCancel,
			onLoading,
		};

		return setModalProps?.(props);
	}, [
		item.tipoArquivoImportacaoID,
		item.tipoArquivoMovimentoID,
		formatoArquivo.mimetype,
		onCancel,
		onLoading,
		setModalProps,
	]);

	const itemProps = useMemo<IItemProps>(() => {
		const props: IItemProps = {
			item,
			statusBadgeProps,
			envioMaisRecente,
			responsavel,
			formatoArquivo,
			isModalLoading,
			openModal,
		};

		return props;
	}, [item, statusBadgeProps, envioMaisRecente, responsavel, formatoArquivo, isModalLoading, openModal]);

	const fallback = useMemo(() => {
		return layout === 'cards' ? <ArquivoCardItemFallback /> : <ArquivoListItemFallback />;
	}, [layout]);

	return isLoading ? (
		fallback
	) : (
		<Suspense fallback={fallback}>
			{layout === 'cards' ? <ArquivoCardItem {...itemProps} /> : <ArquivoListItem {...itemProps} />}
		</Suspense>
	);
});

export default ArquivoCardListItem;
