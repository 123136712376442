import { StatusCard } from '@jcm/poc-design-system';
import { Col, Skeleton } from 'antd';
import { memo } from 'react';
import { IArquivoCardItemFallbackProps } from './index.types';

const ArquivoCardItemFallback: React.FC<IArquivoCardItemFallbackProps> = memo(({ id }) => {
	return (
		<Col sm={24} md={12} lg={8} xl={8} xxl={6}>
			<StatusCard status={'info'} hoverable id={id}>
				<Skeleton active />
			</StatusCard>
		</Col>
	);
});

export default ArquivoCardItemFallback;
