import dayjs from 'dayjs';
import { createContext, memo, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';

import { corrigirFusoHorario } from '../../helpers';
import { ApiContext, GetInfoArquivoResponseType, PostArquivoResponseType } from '../apiContext';
import { AuthContext } from '../authContext';
import { LocalStorageContext } from '../localStorageContext';
import { MessageContext } from '../messageContext';

import { AntSelectorDateType, ArquivoType, EnvioType } from '../index.types';
import { DataContextProviderReducerType, DefaultDataContext } from './index.types';

const DataContext = createContext<DefaultDataContext>(undefined!);

export const dataContextProviderReducer = (
	prev: DataContextProviderReducerType,
	next: Partial<DataContextProviderReducerType>
) => {
	return { ...prev, ...next };
};

const DataContextProvider: React.FC<{ children: React.ReactNode }> = memo((props) => {
	const messageKey = 'dataContext';

	const { getItem, setItem } = useContext(LocalStorageContext);
	const { getArquivos, getMesDeProcessamento, getClientesAutorizados } = useContext(ApiContext);
	const { user, checkSessionExpiration } = useContext(AuthContext);

	const initialState = useMemo<DataContextProviderReducerType>(() => {
		return {
			arquivos: [],
			clientes: [],
			isLoading: true,
			isError: false,
			clienteID: getItem('lastClienteID'),
			tipoModuloID: getItem('lastTipoModuloID'),
		};
	}, [getItem]);

	const [state, dispatch] = useReducer(dataContextProviderReducer, initialState);

	const limparArquivos = useCallback(() => dispatch({ arquivos: [] }), []);

	const setUltimoResultado = useCallback((value: PostArquivoResponseType) => dispatch({ ultimoResultado: value }), []);

	const setClienteID = useCallback((value: string) => dispatch({ clienteID: value }), []);

	const changeTipoModulo = useCallback((value: string) => dispatch({ arquivos: [], tipoModuloID: value }), []);

	const setDataReferencia = useCallback((value: AntSelectorDateType) => dispatch({ dataReferencia: value }), []);

	const { messageLoading, messageSuccess, messageError } = useContext(MessageContext);

	// Trata a data de referência
	const fetchMesDeProcessamento = useCallback(async () => {
		if (!state.clienteID || !state.tipoModuloID) return;

		const resMesDeProcessamento = await getMesDeProcessamento(state.clienteID, state.tipoModuloID);
		return resMesDeProcessamento.data;
	}, [state.clienteID, state.tipoModuloID, getMesDeProcessamento]);

	useEffect(() => {
		if (!user?.sub) return;

		messageLoading(messageKey, 'Buscando mês de processamento...');

		fetchMesDeProcessamento()
			.then((response) => {
				if (!response) return;

				const dataRecebida = dayjs(response.dataProcessamento.slice(0, 10)); // GERANDO NOVA DATA, IGNORANDO O HORÁRIO
				// console.log(dataProcessamento); // HORÁRIO UTC SALVO NO BANCO NA VERDADE É -3

				dispatch({ dataReferencia: dataRecebida, dataReferenciaInicial: dataRecebida, isError: false });
				messageSuccess(messageKey, 'Mês de processamento carregado com sucesso.');
			})
			.catch((err) => {
				console.error(err);
				dispatch({ isError: true });
				checkSessionExpiration();
				messageError(messageKey, 'Erro ao buscar mês de processamento!');
			});
	}, [
		user?.sub,
		state.clienteID,
		fetchMesDeProcessamento,
		messageLoading,
		messageSuccess,
		messageError,
		checkSessionExpiration,
	]);

	// Trata os arquivos
	const fetchArquivos = useCallback(async () => {
		if (!state.clienteID || !state.tipoModuloID) return;

		const resArquivos = await getArquivos(state.clienteID, state.dataReferencia, state.tipoModuloID);
		return resArquivos.data;
	}, [state.clienteID, state.dataReferencia, state.tipoModuloID, getArquivos]);

	const fetchClientes = useCallback(async () => {
		const resClientes = (await getClientesAutorizados()).data;

		dispatch({ clientes: resClientes });

		const clienteIds = resClientes.map((x) => x.ClienteID);

		if (!state.clienteID || !clienteIds.includes(Number(state.clienteID))) {
			dispatch({ clienteID: clienteIds[0]?.toString() });
		}
	}, [getClientesAutorizados, dispatch, state.clienteID]);

	const mapResArquivos = useCallback((arquivo: GetInfoArquivoResponseType) => {
		const envio = arquivo.envio.map((envio) => {
			const dataHoraImportacao = corrigirFusoHorario(envio.dataHoraImportacao);

			return { ...envio, dataHoraImportacao } as EnvioType;
		});

		return { ...arquivo, envio } as ArquivoType;
	}, []);

	const fetchData = useCallback(async () => {
		const resArquivos = await fetchArquivos();

		if (!resArquivos) return;

		dispatch({ arquivos: resArquivos.map(mapResArquivos) });
	}, [fetchArquivos, mapResArquivos]);

	useEffect(() => {
		if (!user?.sub) return;

		dispatch({ isLoading: true });
		//if (!state.dataReferencia) return;

		messageLoading(messageKey, 'Buscando clientes...');

		if (state.clientes.length === 0) {
			fetchClientes()
				.then(() => {
					messageSuccess(messageKey, 'Busca finalizada!');
					dispatch({ isError: false });
				})
				.catch((e) => {
					console.error(e);
					dispatch({ isError: true });
					messageError(messageKey, 'Erro na busca de clientes!');
					checkSessionExpiration();
				})
				.finally(() => {
					//dispatch({ isLoading: false });
				});
		}

		if (state.dataReferencia !== undefined) {
			messageLoading(messageKey, 'Lendo arquivos...');

			fetchData()
				.then(() => {
					messageSuccess(messageKey, 'Leitura finalizada!');
					dispatch({ isError: false });
				})
				.catch((e) => {
					dispatch({ isError: true });
					messageError(messageKey, 'Erro na leitura dos arquivos!');
					checkSessionExpiration();
				})
				.finally(() => {
					dispatch({ isLoading: false });
				});
		}
	}, [
		user?.sub,
		state.dataReferencia,
		fetchData,
		messageLoading,
		messageSuccess,
		messageError,
		checkSessionExpiration,
		fetchClientes,
	]);

	// Armazena dataReferencia
	useEffect(() => {
		if (!state.dataReferencia) return;
		setItem('lastDataReferencia', state.dataReferencia.toISOString());
	}, [state.dataReferencia, setItem]);

	// Armazena clienteID
	useEffect(() => {
		if (!state.clienteID) return;

		setItem('lastClienteID', state.clienteID);
	}, [state.clienteID, setItem]);

	// Armazena tipoModuloID
	useEffect(() => {
		if (!state.tipoModuloID) return;

		setItem('lastTipoModuloID', state.tipoModuloID);
	}, [state.tipoModuloID, setItem]);

	const getArquivoById = useCallback(
		(id: number) => state.arquivos.find((item) => item.tipoArquivoImportacaoID === id),
		[state.arquivos]
	);

	const context: DefaultDataContext = useMemo(() => {
		return {
			arquivos: state.arquivos,
			isLoading: state.isLoading,
			isError: state.isError,
			clienteID: state.clienteID,
			clientes: state.clientes,
			setClienteID,
			changeTipoModulo,
			dataReferenciaInicial: state.dataReferenciaInicial,
			dataReferencia: state.dataReferencia,
			setDataReferencia,
			getArquivoById,
			fetchData,
			ultimoResultado: state.ultimoResultado,
			setUltimoResultado,
			limparArquivos,
			tipoModuloID: state.tipoModuloID,
		};
	}, [
		state.arquivos,
		state.isLoading,
		state.isError,
		state.clienteID,
		setClienteID,
		changeTipoModulo,
		state.dataReferenciaInicial,
		state.dataReferencia,
		setDataReferencia,
		getArquivoById,
		fetchData,
		state.ultimoResultado,
		setUltimoResultado,
		state.clientes,
		limparArquivos,
		state.tipoModuloID,
	]);

	return <DataContext.Provider value={context}>{props.children}</DataContext.Provider>;
});

export * from './index.types';
export { DataContext, DataContextProvider };
