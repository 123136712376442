import { CheckCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Checkbox, CheckboxGroup, GhostButton, SearchBox } from '@jcm/poc-design-system';
import { Divider, Popover, Space } from 'antd';
import { memo, useMemo } from 'react';

import { getArquivoStatusTradutorMap } from '../../store';

import { IFilterPopoverProps } from './index.types';

import styles from './index.module.scss';

const FilterPopover: React.FC<IFilterPopoverProps> = memo((props) => {
	const arquivoStatusTradutor = useMemo(() => getArquivoStatusTradutorMap(), []);

	return (
		<Space direction='vertical' className={styles.filterPopover}>
			<Divider className={styles.divider} />
			<SearchBox
				id='filter-popover-searchbox'
				size='middle'
				placeholder='Pesquisar'
				className={styles.searchBox}
				value={props.searchQuery}
				onChange={props.onChangeSearchQuery}
			/>
			<Divider className={styles.divider} />
			<Space direction='vertical' className='w-100' id='filter-popover-options'>
				<Popover
					trigger='click'
					content={
						<CheckboxGroup
							id='filter-popover-checkbox-group'
							value={props.statusFilter}
							onChange={props.onChangeStatusFilter}
						>
							<Space direction='vertical'>
								{Array.from(arquivoStatusTradutor.keys()).map((key) => {
									const tradutorValue = arquivoStatusTradutor.get(key);
									return (
										<Checkbox key={key} value={key}>
											{tradutorValue?.label}
										</Checkbox>
									);
								})}
							</Space>
						</CheckboxGroup>
					}
					placement='right'
					arrow={false}
				>
					<GhostButton
						size='middle'
						className={styles.optionsBtn}
						type={props.statusFilter.length > 0 ? 'primary' : 'text'}
						icon={<CheckCircleOutlined />}
					>
						<Space align='baseline' className='w-100 justify-content-around'>
							<span>Status de transmissão</span>
							<span className={styles.optionsBtnArrow}>
								<RightOutlined />
							</span>
						</Space>
					</GhostButton>
				</Popover>
			</Space>
		</Space>
	);
});

export default FilterPopover;
