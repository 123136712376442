import { DatePicker } from '@jcm/poc-design-system';
import { Form } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { memo, useCallback, useContext } from 'react';

import { DataContext } from '../store';

export const MesDeProcessamentoDatePicker = memo(() => {
	const { dataReferencia, dataReferenciaInicial, setDataReferencia, isLoading } = useContext(DataContext);

	const disabledDate: RangePickerProps['disabledDate'] = useCallback(
		(current: dayjs.Dayjs) => !!dataReferenciaInicial && current > dataReferenciaInicial,
		[dataReferenciaInicial]
	);

	return (
		<Form>
			<Form.Item label='Mês de processamento'>
				<DatePicker
					id='mes-de-processamento-date-picker'
					picker='month'
					value={dataReferencia}
					onChange={setDataReferencia}
					format={'MMMM YYYY'}
					disabled={isLoading}
					allowClear={false}
					disabledDate={disabledDate}
				/>
			</Form.Item>
		</Form>
	);
});
