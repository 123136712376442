import { StatusCard } from '@jcm/poc-design-system';
import { Skeleton } from 'antd';
import { memo } from 'react';

import { IArquivoListItemFallbackProps } from './index.types';

const ArquivoListItemFallback: React.FC<IArquivoListItemFallbackProps> = memo(({ id }) => {
	return (
		<StatusCard status={'info'} hoverable id={id}>
			<Skeleton active />
		</StatusCard>
	);
});

export default ArquivoListItemFallback;
