import { NotFound } from '@jcm/poc-design-system';
import { Suspense, lazy, memo, useCallback, useContext, useMemo } from 'react';
import { LoaderFunctionArgs, Route, RouterProvider, createRoutesFromElements } from 'react-router-dom';

import { ContentLayout } from '../layouts';
import { sentryCreateBrowserRouter } from '../sentry';
import { AuthContext } from '../store';

import { HistoricoDetalhesLoaderDataType, HistoricoLoaderDataType } from './index.types';

import '../assets/scss/style.scss';
import Arrecadacao from '../pages/Arrecadacao';
import Tesouraria from '../pages/Tesouraria';
import Emprestimo from '../pages/Emprestimo';
import Contabil from '../pages/Contabil';
import FolhaBeneficio from '../pages/FolhaBeneficio';

const Home = lazy(() => import('../pages/Home'));

const Historico = lazy(() => import('../pages/Historico'));
const HistoricoDetalhes = lazy(() => import('../pages/Historico/HistoricoDetalhes'));
const HistoricoTabela = lazy(() => import('../pages/Historico/HistoricoTabela'));

const HomePage = memo(() => {
	return (
		<Suspense fallback='Carregando home...'>
			<Home />
		</Suspense>
	);
});

const ArrecadacaoPage = memo(() => {
	return (
		<Suspense fallback='Carregando arrecadação...'>
			<Arrecadacao />
		</Suspense>
	);
});

const TesourariaPage = memo(() => {
	return (
		<Suspense fallback='Carregando tesouraria...'>
			<Tesouraria />
		</Suspense>
	);
});

const ContabilPage = memo(() => {
	return (
		<Suspense fallback='Carregando Contabil...'>
			<Contabil />
		</Suspense>
	);
});

const FolhaBeneficioPage = memo(() => {
	return (
		<Suspense fallback='Carregando Folha de Benefícios...'>
			<FolhaBeneficio />
		</Suspense>
	);
});

const EmprestimoPage = memo(() => {
	return (
		<Suspense fallback='Carregando Emprestimo...'>
			<Emprestimo />
		</Suspense>
	);
});

const HistoricoPage = memo(() => {
	return (
		<Suspense fallback='Carregando histórico...'>
			<Historico />
		</Suspense>
	);
});

const HistoricoTabelaPage = memo(() => {
	return (
		<Suspense fallback='Carregando tabela...'>
			<HistoricoTabela />
		</Suspense>
	);
});

const HistoricoDetalhesPage = memo(() => {
	return (
		<Suspense fallback='Carregando detalhes...'>
			<HistoricoDetalhes />
		</Suspense>
	);
});

const Routes = memo(() => {
	const authContext = useContext(AuthContext);

	const historicoLoader = useCallback(async (e: LoaderFunctionArgs) => {
		const id = e.params.id;
		return { id };
	}, []);

	const historicoPageHandleCrumb = useCallback((data?: HistoricoLoaderDataType) => {
		const path = !data || !data.id ? undefined : `/historico/${data.id}`;

		return { label: 'Histórico da Transmissão', path };
	}, []);

	const historicoDetalhesLoader = useCallback((e: LoaderFunctionArgs) => {
		const { id, importacaoId } = e.params;
		const versao = new URLSearchParams(e.request.url.substring(e.request.url.indexOf('?'))).get('versao');

		return { versao, id, importacaoId };
	}, []);

	const historicoDetalhesHandleCrumb = useCallback((data?: HistoricoDetalhesLoaderDataType) => {
		const path = data ? `/historico/${data?.id}/detalhes/${data?.importacaoId}` : undefined;

		return { label: 'Detalhes da Transmissão', path };
	}, []);

	const routes = useMemo(() => {
		return createRoutesFromElements(
			<Route>
				<Route
					path='/'
					handle={{ crumb: () => ({ path: '/', label: 'Página Inicial' }) }}
					errorElement={<></>}
					element={<ContentLayout />}
				>
					<Route index element={<HomePage />} />

					<Route path='/arrecadacao' element={<ArrecadacaoPage />} />
					<Route path='/tesouraria' element={<TesourariaPage />} />
					<Route path='/emprestimo' element={<EmprestimoPage />} />
					<Route path='/contabil' element={<ContabilPage />} />
					<Route path='/folha' element={<FolhaBeneficioPage />} />

					<Route
						path='/historico/:id'
						element={<HistoricoPage />}
						loader={historicoLoader}
						handle={{ crumb: historicoPageHandleCrumb }}
					>
						<Route index element={<HistoricoTabelaPage />} />
						<Route
							path='/historico/:id/detalhes/:importacaoId'
							element={<HistoricoDetalhesPage />}
							loader={historicoDetalhesLoader}
							handle={{ crumb: historicoDetalhesHandleCrumb }}
						/>
					</Route>
				</Route>

				<Route path='*' element={<NotFound />} />
			</Route>
		);
	}, [historicoLoader, historicoPageHandleCrumb, historicoDetalhesLoader, historicoDetalhesHandleCrumb]);

	const router = useMemo(() => sentryCreateBrowserRouter(routes), [routes]);

	return authContext.isLoading ? null : <RouterProvider router={router} />;
});

export default Routes;
export * from './index.types';
