import { Button, Dragger, StatusCard } from '@jcm/poc-design-system';
import { Col, Divider, Form, Modal, Row, Space } from 'antd';
import type { UploadChangeParam, UploadFile } from 'antd/es/upload/interface';
import { AxiosProgressEvent } from 'axios';
import { lazy, memo, Suspense, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
	ApiContext,
	ComponentStatusType,
	DataContext,
	getConfigResumoFromTipoArquivoMovimentoID,
	PostArquivoResponseType,
} from '../../../store';

import { IAdicionarArquivoModalProps, UploadStateType } from './index.types';

import colors from '../../../assets/scss/_colors.module.scss';
import styles from './index.module.scss';

const TabelaResumoContribuicoes = lazy(() => import('./TabelaResumoContribuicoes'));
const ResumoRegistrosTransmitidos = lazy(() => import('./ResumoRegistrosTransmitidos'));
const DraggerContent = lazy(() => import('./DraggerContent'));

const AdicionarArquivoModal: React.FC<IAdicionarArquivoModalProps> = memo(
	({ tipoArquivoImportacaoID, tipoArquivoMovimentoID, formatoArquivo, open, onOk, onCancel, onLoading }) => {
		const [fileList, setFileList] = useState<UploadFile[]>([]);
		const [resumo, setResumo] = useState<PostArquivoResponseType>();

		const [uploadState, setUploadState] = useState<UploadStateType>({});
		const [status, setStatus] = useState<ComponentStatusType>('info');

		const { postArquivo } = useContext(ApiContext);
		const { dataReferencia, fetchData, setUltimoResultado, clienteID } = useContext(DataContext);

		const navigate = useNavigate();

		useEffect(() => {
			if (!uploadState.isComplete) return;

			if (uploadState.isError) {
				return uploadState.isFatal ? setStatus('error') : setStatus('warning');
			}

			return setStatus('success');
		}, [uploadState.isComplete, uploadState.isError, uploadState.isFatal]);

		const resetModal = useCallback(() => {
			// Retorna tudo ao estado inicial!
			setFileList([]);
			setResumo(undefined);
			setStatus('info');
			setUploadState({});
		}, []);

		const doOnCancel = useCallback(() => {
			if (uploadState.isLoading) return;
			resetModal();
			onCancel();
		}, [uploadState.isLoading, resetModal, onCancel]);

		const doOnOk = useCallback(() => {
			onOk?.();
			navigate(`/historico/${tipoArquivoImportacaoID}/detalhes/${resumo?.arquivoImportacaoID}`);
		}, [tipoArquivoImportacaoID, resumo?.arquivoImportacaoID, onOk, navigate]);

		const handleChange = useCallback((e: UploadChangeParam<UploadFile<any>>) => {
			setFileList(e.fileList);
		}, []);

		const modalRender = useCallback(
			(children: React.ReactNode) => {
				return (
					<StatusCard status={status} className={styles.modalCard}>
						{children}
					</StatusCard>
				);
			},
			[status]
		);

		const doLoading = useCallback(
			(value: boolean) => {
				onLoading(value);
			},
			[onLoading]
		);

		const getFormData = useCallback(
			({ file }: any) => {
				if (!dataReferencia) return console.error('Sem data de referência!');

				const formData = new FormData();
				formData.append('arquivo', file);
				formData.append('nomeArquivo', file.name);
				formData.append('tipoArquivoImportacaoID', tipoArquivoImportacaoID.toString());
				formData.append('dataCompetencia', new Date().toISOString());
				formData.append('dataReferencia', dataReferencia?.toISOString());
				return formData;
			},
			[dataReferencia, tipoArquivoImportacaoID]
		);

		const doUploadProgress = useCallback(
			(options: any) => (progressEvent: AxiosProgressEvent) => {
				if (!progressEvent.total) return console.info('Aguardando...');

				const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
				const percent = progress === 100 ? 99 : progress;

				options.onProgress?.({ percent });
			},
			[]
		);

		const doCustomRequest = useCallback(
			(options: any) => {
				if (!clienteID) return;

				setUploadState({ isLoading: true });
				doLoading(true);

				const formData = getFormData(options);
				if (!formData) return console.error('Erro ao gerar FormData!');

				const requestConfig = {
					headers: { 'Content-Type': 'multipart/form-data' },
					onUploadProgress: doUploadProgress(options),
				};

				postArquivo(clienteID, formData, requestConfig)
					.then(({ data }) => {
						setUltimoResultado(data);
						const { inconsistencias } = data;

						if (inconsistencias.length > 0) {
							const isFatal = inconsistencias.some((item) => item.erroFatal);

							setUploadState((prev) => ({ ...prev, isError: true, isFatal }));
						}

						setResumo(data);
						options.onSuccess?.('Ok');
					})
					.catch((err) => {
						console.error(err);
						setUploadState((prev) => ({
							...prev,
							isError: true,
							isFatal: true,
							errorMessage: err?.response?.data ?? 'Erro inesperado na transmissão!',
						}));
						options.onError?.(err);
					})
					.finally(async () => {
						setUploadState((prev) => ({ ...prev, isLoading: false, isComplete: true }));
						doLoading(false);
						await fetchData();
					});
			},
			[postArquivo, doUploadProgress, getFormData, doLoading, fetchData, setUltimoResultado, clienteID]
		);

		const configResumoImportacao = useMemo(() => {
			return getConfigResumoFromTipoArquivoMovimentoID(tipoArquivoMovimentoID);
		}, [tipoArquivoMovimentoID]);

		const getModalFooter = useCallback(() => {
			return uploadState.isComplete ? (
				<>
					{resumo && (
						<Space className='w-100' direction='vertical' style={{ rowGap: '0px' }}>
							{configResumoImportacao.mostrarContribuicoes && (
								<Suspense fallback='Carregando tabela...'>
									<TabelaResumoContribuicoes item={resumo} />
								</Suspense>
							)}

							{configResumoImportacao.mostrarRegistros && (
								<Suspense fallback='Carregando resumo...'>
									<ResumoRegistrosTransmitidos item={resumo} />
								</Suspense>
							)}
						</Space>
					)}
					<Row className='justify-content-center'>
						<Divider className={styles.divider} />
						{uploadState.isComplete && resumo?.arquivoImportacaoID && (
							<Col lg={6} className={styles.btnCol}>
								<Button type='primary' size='small' className={styles.btnHistorico} onClick={doOnOk}>
									Ver registros
								</Button>
							</Col>
						)}
						<Col lg={6} className={styles.btnCol}>
							<Button type='primary' size='small' className={styles.btnFechar} danger onClick={doOnCancel}>
								Fechar
							</Button>
						</Col>
					</Row>
				</>
			) : null;
		}, [
			uploadState.isComplete,
			configResumoImportacao.mostrarContribuicoes,
			configResumoImportacao.mostrarRegistros,
			resumo,
			doOnCancel,
			doOnOk,
		]);

		const modalFooter = useMemo(() => getModalFooter(), [getModalFooter]);

		const acceptMimeType = useMemo(() => formatoArquivo ?? '*', [formatoArquivo]);

		return (
			<Modal
				centered
				open={open}
				onOk={onOk}
				onCancel={doOnCancel}
				modalRender={modalRender}
				footer={modalFooter}
				width={600}
			>
				<Form className={styles.form}>
					<Dragger
						id='adicionar-arquivo-modal-dragger'
						data-testid='adicionar-arquivo-modal-dragger'
						maxCount={1}
						disabled={uploadState.isLoading || uploadState.isComplete}
						className={styles.dragger}
						progress={{ strokeWidth: 3, strokeColor: colors.gold }}
						showUploadList={{ showRemoveIcon: false }}
						fileList={fileList}
						onChange={handleChange}
						customRequest={doCustomRequest}
						accept={acceptMimeType}
					>
						<Suspense fallback='Carregando conteúdo...'>
							<DraggerContent state={uploadState} inconsistenciasFatais={resumo?.quantidadeInconsistenciasFatais} />
						</Suspense>
					</Dragger>
				</Form>
			</Modal>
		);
	}
);

export default AdicionarArquivoModal;
export * from './index.types';
