import { ContainerOutlined, LoadingOutlined } from '@ant-design/icons';
import { CardList } from '@jcm/poc-design-system';
import { Result } from 'antd';
import { memo, useCallback, useContext, useMemo, useReducer } from 'react';

import { ArquivoType, DataContext } from '../../store';

import AdicionarArquivoModal, { IAdicionarArquivoModalProps } from '../Modals/AdicionarArquivoModal';
import ArquivoCardListItem from './ArquivoCardListItem';

import { CardListRender, IArquivosCardListProps } from './index.types';

import colors from '../../assets/scss/_colors.module.scss';
import styles from './index.module.scss';

export const arquivosCardListReducer = (
	prev: IAdicionarArquivoModalProps,
	next: Partial<IAdicionarArquivoModalProps>
) => {
	return { ...prev, ...next } as IAdicionarArquivoModalProps;
};

export const getInitialAdicionarArquivoModalState = (overrides: Partial<IAdicionarArquivoModalProps>) => {
	const defaultState: IAdicionarArquivoModalProps = {
		open: false,
		tipoArquivoMovimentoID: 1,
		tipoArquivoImportacaoID: 0,
		onCancel: () => {},
		onLoading: () => {},
		onOk: () => {},
	};

	return { ...defaultState, ...overrides } as IAdicionarArquivoModalProps;
};

const ArquivosCardList: React.FC<IArquivosCardListProps> = memo(({ items, layout }) => {
	const { isLoading } = useContext(DataContext);

	const [adicionarArquivoModalState, adicionarArquivoModalDispatch] = useReducer(
		arquivosCardListReducer,
		{},
		getInitialAdicionarArquivoModalState
	);

	const render: CardListRender<ArquivoType> = useCallback(
		(item, layout) => {
			return (
				<ArquivoCardListItem
					item={item}
					layout={layout}
					isLoading={isLoading}
					setModalProps={adicionarArquivoModalDispatch}
				/>
			);
		},
		[isLoading]
	);

	const resultIcon = useMemo(() => {
		const iconStyle = { color: colors.textSecondary, fontSize: isLoading ? '32px' : '64px' };

		return isLoading ? <LoadingOutlined style={iconStyle} /> : <ContainerOutlined style={iconStyle} />;
	}, [isLoading]);

	return (
		<section className={styles.cardListContainer}>
			<AdicionarArquivoModal {...adicionarArquivoModalState} />
			{items.length > 0 ? (
				<CardList items={items} render={render} layout={layout} />
			) : (
				<Result subTitle={isLoading ? 'Carregando...' : 'Sem arquivos a exibir...'} icon={resultIcon} />
			)}
		</section>
	);
});

export default ArquivosCardList;
export * from './index.types';
